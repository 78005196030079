* {
    box-sizing: border-box;
    font-family: 'lato';
}

a:visited {
    color: #FF6250;
}

.m-1 {
    margin: 1rem !important;
}
.ml-1 {
    margin-left: 1rem !important;
}
.mr-1 {
    margin-right: 1rem !important;
}
.mt-1 {
    margin-top: 1rem !important;
}
.mb-1 {
    margin-bottom: 1rem !important;
}
.my-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}
.mx-1 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
}

.m-2 {
    margin: 2rem !important;
}
.ml-2 {
    margin-left: 2rem !important;
}
.mr-2 {
    margin-right: 2rem !important;
}
.mt-2 {
    margin-top: 2rem !important;
}
.mb-2 {
    margin-bottom: 2rem !important;
}
.my-2 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
}
.mx-2 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
}

.m-3 {
    margin: 3rem !important;
}
.ml-3 {
    margin-left: 3rem !important;
}
.mr-3 {
    margin-right: 3rem !important;
}
.mt-3 {
    margin-top: 3rem !important;
}
.mb-3 {
    margin-bottom: 3rem !important;
}
.my-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
}
.mx-3 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
}

